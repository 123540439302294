import React from "react";
import "./Tree.css";
import { renderTree } from "./TreeRenderer";
import { paarTreeData } from "./data/PaarTreeData";
import { gervaisTreeData } from "./data/GervaisTreeData";
import { potocnjakTreeData } from "./data/PotocnjakTreeData";

const DOM_ID = "treediagram";

function Tree() {
  const [currentTreeId, setCurrentTreeId] = React.useState("Paar");

  React.useEffect(() => {
    let treeData;
    switch (currentTreeId) {
      case "Paar":
        treeData = paarTreeData;
        break;
      case "Gervais":
        treeData = gervaisTreeData;
        break;
      case "Potocnjak":
        treeData = potocnjakTreeData;
        break;
      default:
        treeData = paarTreeData;
        break;
    }

    const onChangeTree = connectionTreeId => setCurrentTreeId(connectionTreeId);

    renderTree(DOM_ID, treeData, onChangeTree);
  }, [currentTreeId]);

  return <div key={currentTreeId} id={DOM_ID} className="tree-container"></div>;
}

export default Tree;
