export const elviraLjubicaPaar = {
  name: "Elvira Ljubica Paar",
  class: "woman",
  extra: {
    image: "elivrapaar.PNG",
    bornName: "Potočnjak",
    birthDate: "21.3.1921",
    deathDate: "28.12.2008"
  }
};

export const paarTreeData = [
  {
    name: "Johann Paar",
    class: "man",
    extra: {
      image: "johannpaar.PNG",
      nickname: "Johanna",
      location: "Leibnitz / Samobor (Gajeva 50)",
      birthDate: "25.11.1842",
      deathDate: "22.9.1904",
      description: "U Samobor došao oko 1870. iz Leobena kraj Graza"
    },
    marriages: [
      {
        spouse: {
          name: "Marija Paar",
          class: "woman",
          extra: {
            image: "marijapaar.PNG",
            bornName: "Prutsch",
            location: "Volsburg, Štajerska",
            birthDate: "1838",
            deathDate: "15.12.1916"
          }
        },
        children: [
          {
            name: "Paula Čelhar",
            class: "woman",
            extra: {
              image: "paulacelhar.PNG",
              bornName: "Paar",
              birthDate: "15.11.1878"
            },
            children: [
              {
                name: "Ivan Čelhar",
                class: "man",
                extra: {
                  location: "Brežice"
                }
              },
              {
                name: "Lila Vičić",
                class: "woman",
                extra: {
                  image: "lilavicic.PNG",
                  bornName: "Čelhar",
                  location: "Maribor"
                },
                marriages: [
                  {
                    spouse: {
                      name: "Janez Vičić",
                      class: "man",
                      extra: {
                        image: "janezvicic.PNG",
                        bornName: "Čelhar",
                        location: "Maribor"
                      }
                    },
                    children: [
                      {
                        name: "Majda Klarić",
                        class: "woman",
                        extra: {
                          image: "majdaklaric.PNG",
                          bornName: "Vičić",
                          occupation: "Građevinski tehničar"
                        },
                        marriages: [
                          {
                            spouse: {
                              name: "Nikola Klarić",
                              class: "man",
                              extra: {
                                image: "nikolaklaric.PNG"
                              }
                            }
                          }
                        ]
                      },
                      {
                        name: "Breda Novak",
                        class: "woman",
                        extra: {
                          image: "bredanovak.PNG",
                          bornName: "Vičić",
                          occupation: "Odgajateljica"
                        },
                        marriages: [
                          {
                            spouse: {
                              name: "Franc Novak",
                              class: "man",
                              extra: {
                                image: "francnovak.PNG",
                                occupation: "Inž. strojarstva"
                              }
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: "Josipa Dolinšak",
            class: "woman",
            extra: {
              image: "josipadolinsak.PNG",
              nickname: "Mima",
              bornName: "Paar",
              birthDate: "22.3.1879"
            }
          },
          {
            name: "Viktorija Šestak",
            class: "woman",
            extra: {
              image: "viktorijasestak.PNG",
              nickname: "Vika",
              bornName: "Paar",
              birthDate: "28.12.1875",
              deathDate: "21.3.1971"
            }
          },
          {
            name: "Adolf Paar",
            class: "man",
            extra: {
              image: "adolfpaar.PNG",
              birthDate: "16.6.1874",
              deathDate: "5.10.1918",
              location: "Samobor",
              occupation: "Zidar"
            },
            marriages: [
              {
                spouse: {
                  name: "Barica Paar",
                  class: "woman",
                  extra: {
                    image: "baricapaar.PNG",
                    bornName: "Mataušić",
                    birthDate: "17.10.1885",
                    deathDate: "22.10.1962"
                  }
                },
                children: [
                  {
                    name: "Adolf Paar",
                    class: "man",
                    extra: {
                      image: "adolfpaar2.PNG",
                      occupation: "Nadcestar",
                      birthDate: "21.10.1902",
                      deathDate: "22.2.1984"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Dragica Paar",
                          class: "woman",
                          extra: {
                            image: "dragicapaar.PNG",
                            bornName: "Noršić",
                            birthDate: "7.10.1919",
                            deathDate: "10.5.2008"
                          }
                        },
                        children: [
                          {
                            name: "Adolf Paar",
                            class: "man",
                            extra: {
                              image: "adolfpaar3.PNG",
                              occupation: "Inž. građevinarstva",
                              birthDate: "12.3.1948"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Zdenka Paar",
                                  class: "woman",
                                  extra: {
                                    image: "zdenkapaarnovak.PNG",
                                    bornName: "Novak",
                                    occupation: "Farmaceutski tehničar",
                                    birthDate: "26.9.1949"
                                  }
                                },
                                children: [
                                  {
                                    name: "Aleksandra Paar-Bortas",
                                    class: "woman",
                                    extra: {
                                      image: "aleksandrapaar.PNG",
                                      birthDate: "21.2.1973"
                                    },
                                    marriages: [
                                      {
                                        spouse: {
                                          name: "Dražen Bortas",
                                          class: "man",
                                          extra: {
                                            image: "drazenbortas.PNG",
                                            birthDate: "8.11.1967"
                                          }
                                        },
                                        children: [
                                          {
                                            name: "Dominik Bortas",
                                            class: "man",
                                            extra: {
                                              image: "dominikbortas.PNG",
                                              birthDate: "24.10.1995"
                                            }
                                          },
                                          {
                                            name: "Dora Bortas",
                                            class: "woman",
                                            extra: {
                                              image: "dorabortas.PNG",
                                              birthDate: "10.8.1999"
                                            }
                                          },
                                          {
                                            name: "Marta Bortas",
                                            class: "woman",
                                            extra: {
                                              image: "martabortas.PNG",
                                              birthDate: "10.8. 1999"
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    name: "Tatjana Paar",
                                    class: "woman",
                                    extra: {
                                      image: "tatjanapaar.PNG",
                                      occupation: "Dipl. inž. elektrotehnike",
                                      birthDate: "10.11.1969"
                                    }
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: "Ivan Paar",
                    class: "man",
                    extra: {
                      image: "ivanpaar.PNG",
                      occupation: "Zidar",
                      birthDate: "24.4.1906",
                      deathDate: "8.9.1965"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Ana Paar",
                          class: "woman",
                          extra: {
                            image: "anapaar.PNG",
                            bornName: "Noršić",
                            birthDate: "8.7.1910",
                            deathDate: "28.6.1992"
                          }
                        }
                      }
                    ]
                  },
                  {
                    name: "Milan Paar",
                    class: "man",
                    extra: {
                      image: "milanpaar.PNG",
                      occupation: "Brijač",
                      birthDate: "28.8.1915",
                      deathDate: "25.2.1947"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Amalija Paar",
                          class: "woman",
                          extra: {
                            image: "amalijapaar.PNG",
                            bornName: "Bukvić",
                            birthDate: "1917",
                            deathDate: "1977"
                          }
                        },
                        children: [
                          {
                            name: "Antun Paar",
                            class: "man",
                            extra: {
                              image: "antunpaar.PNG",
                              occupation: "Dipl. inž. geodezije",
                              birthDate: "9.5.1941",
                              deathDate: "17.7.1980"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Branka Paar",
                                  class: "woman",
                                  extra: {
                                    image: "brankapaar.PNG",
                                    occupation: "Dipl. oecc.",
                                    birthDate: "14.12.1942"
                                  }
                                },
                                children: [
                                  {
                                    name: "Marko Paar",
                                    class: "man",
                                    extra: {
                                      image: "markopaar.PNG",
                                      birthDate: "22.9.1973"
                                    }
                                  },
                                  {
                                    name: "Valerija Vulić",
                                    class: "woman",
                                    extra: {
                                      image: "valerijavulic.PNG",
                                      bornName: "Paar",
                                      birthDate: "3.7.1969"
                                    },
                                    marriages: [
                                      {
                                        spouse: {
                                          name: "Ivan Vulić",
                                          class: "man",
                                          extra: {
                                            image: "ivanvulic.PNG",
                                            bornName: "8.3.1966"
                                          }
                                        },
                                        children: [
                                          {
                                            name: "Paula Vulić",
                                            class: "woman",
                                            extra: {
                                              image: "paulavulic.PNG",
                                              birthDate: "10.11.1998"
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: "Dragica Paar",
                    class: "woman"
                  },
                  {
                    name: "Vladimir Paar",
                    class: "man",
                    extra: {
                      image: "vladimirpaar.PNG",
                      occupation: "Krojač",
                      birthDate: "12.5.1912",
                      deathDate: "19.3.1975"
                    },
                    marriages: [
                      {
                        spouse: {
                          ...elviraLjubicaPaar,
                          extra: {
                            ...elviraLjubicaPaar.extra,
                            connectionTreeId: "Potocnjak"
                          }
                        },
                        children: [
                          {
                            name: "Vladimir Paar",
                            class: "man",
                            extra: {
                              image: "vladimirpaar2.PNG",
                              occupation: "Dr. fizike, akademik",
                              birthDate: "11.5.1942"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Nada Pandur-Paar",
                                  class: "woman",
                                  extra: {
                                    image: "nadapaar.PNG",
                                    bornName: "Pandur",
                                    birthDate: "24.7.1949"
                                  }
                                },
                                children: [
                                  {
                                    name: "Dalibor Paar",
                                    class: "man",
                                    extra: {
                                      image: "daliborpaar.PNG",
                                      occupation: "Dr. sc. fizike",
                                      birthDate: "19.9.1969"
                                    },
                                    marriages: [
                                      {
                                        spouse: {
                                          name: "Bernarda Paar",
                                          class: "woman",
                                          extra: {
                                            image: "bernardapaar.PNG",
                                            bornName: "Boban",
                                            occupation: "Univ. mag. vet. med",
                                            birthDate: "3.1.1973"
                                          }
                                        },
                                        children: [
                                          {
                                            name: "Oton Paar",
                                            class: "man",
                                            extra: {
                                              image: "otonpaar.JPG",
                                              birthDate: "26.5.2012"
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    name: "Nils Paar",
                                    class: "man",
                                    extra: {
                                      image: "nilspaar.PNG",
                                      occupation: "Dr. sc. fizike",
                                      birthDate: "28.12.1972"
                                    },
                                    marriages: [
                                      {
                                        spouse: {
                                          name: "Maja Paar",
                                          class: "woman",
                                          extra: {
                                            image: "majapaar.PNG",
                                            bornName: "Hrabak",
                                            occupation: "Dr. sc. medicine",
                                            birthDate: "11.5.1978"
                                          }
                                        },
                                        children: [
                                          {
                                            name: "Laura Paar",
                                            class: "woman",
                                            extra: {
                                              image: "laurapaar.JPG",
                                              birthDate: "21.9.2009"
                                            }
                                          },
                                          {
                                            name: "Maks Paar",
                                            class: "man",
                                            extra: {
                                              image: "makspaar.JPG",
                                              birthDate: "19.5.2012"
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    name: "Petar Paar",
                                    class: "man",
                                    extra: {
                                      image: "petarpaar.jpeg",
                                      occupation: "Programer",
                                      birthDate: "10.9.1988"
                                    },
                                    marriages: [
                                      {
                                        spouse: {
                                          name: "Maša Nekić",
                                          class: "woman",
                                          extra: {
                                            image: "masanekic.JPG",
                                            occupation:
                                              "Podatkovni znanstvenik",
                                            birthDate: "29.12.1987"
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  {
                                    name: "Elizabeta Paar",
                                    class: "woman",
                                    extra: {
                                      image: "elizabetapaar.JPG",
                                      birthDate: "7.1.1995"
                                    }
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: "Josip Paar",
                    class: "man",
                    extra: {
                      image: "josipaar.JPG",
                      occupation: "Stolar",
                      birthDate: "8.3.1909",
                      deathDate: "2.6.1969"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Dragica Paar",
                          class: "woman",
                          extra: {
                            image: "dragicapaar2.JPG",
                            bornName: "Kleščić",
                            birthDate: "31.12.1918"
                          }
                        },
                        children: [
                          {
                            name: "Drago Paar",
                            class: "man",
                            extra: {
                              image: "dragopaar.JPG",
                              occupation: "Radnik",
                              birthDate: "13.11.1944"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Blaženka Paar",
                                  class: "woman",
                                  extra: {
                                    image: "blazenkapaar.JPG",
                                    birthDate: "19.10.1957"
                                  }
                                }
                              }
                            ]
                          },
                          {
                            name: "Milica Gmaz",
                            class: "woman",
                            extra: {
                              image: "milicagmaz.JPG",
                              bornName: "Paar",
                              occupation: "Knjigovođa",
                              birthDate: "28.2.1937"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Slavko Gmaz",
                                  class: "man",
                                  extra: {
                                    image: "slavkogmaz.JPG",
                                    birthDate: "29.3.1936"
                                  }
                                },
                                children: [
                                  {
                                    name: "Leon Gmaz",
                                    class: "man",
                                    extra: {
                                      image: "leongmaz.JPG",
                                      birthDate: "12.3.1962"
                                    }
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: "Dragutin Paar",
                    class: "man",
                    extra: {
                      image: "dragutinpaar.JPG",
                      occupation: "Grafičar",
                      birthDate: "13.5.1918",
                      deathDate: "14.3.1990"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Marija Paar",
                          class: "woman",
                          extra: {
                            image: "marijapaar2.JPG",
                            bornName: "Kodrin",
                            birthDate: "19.6.1917",
                            deathDate: "10.2.1981"
                          }
                        },
                        children: [
                          {
                            name: "Ivan Paar",
                            class: "man",
                            extra: {
                              image: "ivanpaar2.JPG",
                              occupation: "Dipl. inž. geodezije",
                              birthDate: "31.1.1950"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Zdenka Paar",
                                  class: "woman",
                                  extra: {
                                    image: "zdenkapaarpetrovic.JPG",
                                    bornName: "Petrović",
                                    occupation: "Profesor",
                                    birthDate: "10.12.1949"
                                  }
                                },
                                children: [
                                  {
                                    name: "Rinaldo Paar",
                                    class: "man",
                                    extra: {
                                      image: "rinaldopaar.JPG",
                                      occupation: "Mr. sc. geodezije",
                                      birthDate: "5.5.1975"
                                    },
                                    marriages: [
                                      {
                                        spouse: {
                                          name: "Nikolina Paar",
                                          class: "woman",
                                          extra: {
                                            image: "nikolinapaar.JPG",
                                            bornName: "Malek",
                                            birthDate: "13.12.1977"
                                          }
                                        },
                                        children: [
                                          {
                                            name: "Leopold Paar",
                                            class: "man",
                                            extra: {
                                              image: "leopoldpaar.JPG",
                                              birthDate: "25.6.2004"
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    name: "Mihael Paar",
                                    class: "man",
                                    extra: {
                                      image: "mihaelpaar.JPG",
                                      occupation: "Mr. prof. muzike",
                                      birthDate: "12.4.1980"
                                    }
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: "Stjepan Paar",
                    class: "man",
                    extra: {
                      image: "stjepanpaar.JPG",
                      occupation: "Postolar",
                      birthDate: "14.6.1907",
                      deathDate: "23.1.1974"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Marija Paar",
                          class: "woman",
                          extra: {
                            image: "marijapaar3.JPG",
                            bornName: "Dautanec",
                            birthDate: "1909",
                            deathDate: "1.11.1981"
                          }
                        }
                      }
                    ]
                  },
                  {
                    name: "Franjo Paar",
                    class: "man",
                    extra: {
                      image: "franjopaar.JPG",
                      occupation: "Krojač",
                      birthDate: "10.4.1910",
                      deathDate: "21.9.1942"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Marija Paar",
                          class: "woman",
                          extra: {
                            bornName: "Šestak",
                            birthDate: "13.1.1909"
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: "August Paar",
            class: "man",
            extra: {
              image: "augustpaar.JPG",
              nickname: "Gustl",
              birthDate: "28.8.1866",
              deathDate: "24.2.1935",
              occupation: "Zidarski obrtnik"
            },
            children: [
              {
                name: "Anđela Paar",
                class: "woman",
                extra: {
                  image: "andelapaar.JPG"
                },
                children: [
                  {
                    name: "* Kćer u Australiji",
                    class: "woman"
                  }
                ]
              }
            ]
          },
          {
            name: "Bara Hofer",
            class: "woman",
            extra: {
              nickname: "Marija",
              bornName: "Paar",
              birthDate: "4.12.1868"
            }
          },
          {
            name: "Hermina Paar",
            class: "woman",
            extra: {
              image: "herminapaar.JPG",
              birthDate: "30.7.1870"
            },
            children: [
              {
                name: "Mauzika Paar ud. Hoffer",
                class: "woman",
                extra: {
                  image: "mauzikapaar.JPG"
                }
              }
            ]
          },
          {
            name: "Betika Paar",
            class: "woman",
            extra: {
              nickname: "Bara",
              birthDate: "7.11.1871"
            }
          },
          {
            name: "Perica Paar",
            class: "man"
          },
          {
            name: "Ljudevit Paar",
            class: "man",
            extra: {
              image: "ljudevitpaar.JPG",
              birthDate: "24.8.1880",
              deathDate: "1958"
            },
            marriages: [
              {
                spouse: {
                  name: "Ana Paar",
                  class: "woman",
                  extra: {
                    image: "anapaarmatausic.JPG",
                    bornName: "Mataušić",
                    birthDate: "1888",
                    deathDate: "17.7.1965"
                  }
                },
                children: [
                  {
                    name: "Ljudevit Paar",
                    class: "man",
                    extra: {
                      image: "ljudevitpaar2.JPG"
                    }
                  },
                  {
                    name: "Ankica Tkalčić",
                    class: "woman",
                    extra: {
                      image: "ankicatkalcic.JPG",
                      bornName: "Paar"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Josip Tkalčić",
                          class: "man",
                          extra: {
                            image: "josiptkalcic.JPG",
                            birthDate: "8.3.1908",
                            deathDate: "27.2.1975"
                          }
                        },
                        children: [
                          {
                            name: "Ivica Tkalčić",
                            class: "man",
                            extra: {
                              image: "ivicatkalcic.JPG",
                              birthDate: "30.5.1942"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Mara Tkalčić",
                                  class: "woman",
                                  extra: {
                                    image: "maratkalcic.JPG",
                                    birthDate: "16.7.1954"
                                  }
                                }
                              }
                            ]
                          },
                          {
                            name: "Ljerka Leković",
                            class: "woman",
                            extra: {
                              image: "ljerkalekovic.JPG",
                              bornName: "Tkalčić",
                              birthDate: "8.5.1935",
                              deathDate: "29.9.2001"
                            },
                            marriages: [
                              {
                                spouse: {
                                  name: "Đorđije Leković",
                                  class: "man",
                                  extra: {
                                    image: "dordijelekovic.JPG",
                                    birthDate: "23.2.1934"
                                  }
                                }
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: "Milica Mazor",
                    class: "woman",
                    extra: {
                      image: "milicamazor.JPG",
                      bornName: "Paar",
                      birthDate: "17.4.1914",
                      deathDate: "5.12.2008"
                    },
                    marriages: [
                      {
                        spouse: {
                          name: "Mate Mazor",
                          class: "man",
                          extra: {
                            image: "matemazor.JPG",
                            birthDate: "28.7.1912",
                            deathDate: "10.9.1974"
                          }
                        },
                        children: [
                          {
                            name: "Božica Havlik",
                            class: "woman",
                            extra: {
                              image: "bozicahavlik.JPG",
                              bornName: "Mazor",
                              occupation: "Dr. medicine",
                              birthDate: "11.12.1942"
                            }
                          },
                          {
                            name: "Želimir Mazor",
                            class: "man",
                            extra: {
                              image: "zelimirmazor.JPG",
                              occupation: "Dipl. inž.",
                              birthDate: "19.7.1952"
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
