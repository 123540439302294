export const elviraGervais = {
  name: "Elvira Gervais",
  class: "woman"
};

export const gervaisTreeData = [
  {
    name: "Ivan Johan Novak",
    class: "man",
    marriages: [
      {
        spouse: {
          name: "Teraza Modrušan",
          class: "woman"
        },
        children: [
          {
            name: "Ljubica Novak",
            class: "woman",
            marriages: [
              {
                spouse: {
                  name: "Vjekoslav Gervais",
                  class: "man"
                },
                children: [
                  {
                    ...elviraGervais,
                    children: [
                      {
                        name: "... Potočnjak grana",
                        class: "subtree",
                        extra: {
                          connectionTreeId: "Potocnjak"
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
