import React from "react";
import "./App.css";
import Tree from "./Tree";
import { GiFamilyTree } from "react-icons/gi";
import { IoMdMail } from "react-icons/io";

function App() {
  return (
    <div className="root">
      <header className="header">
        <GiFamilyTree className="header-icon" /> Obiteljsko stablo Paar
      </header>
      <Tree />
      <a href="mailto:petarpaar@gmail.com" className="contact" title="Kontakt">
        <IoMdMail />
      </a>
    </div>
  );
}

export default App;
