import { elviraLjubicaPaar } from "./PaarTreeData";
import { elviraGervais } from "./GervaisTreeData";

export const potocnjakTreeData = [
  {
    name: "Željko Potočnjak",
    class: "man",
    extra: {
      occupation: "Učitelj u Ravnoj Gori",
      birthDate: "~1850, Novi Vinodolski",
      deathDate: "~1930"
    },
    children: [
      {
        name: "Marijan Potočnjak",
        class: "man",
        birthDate: "1895",
        deathDate: "1970",
        marriages: [
          {
            spouse: {
              ...elviraGervais,
              extra: {
                connectionTreeId: "Gervais"
              }
            },
            children: [
              {
                name: "Žarko dr. Potočnjak",
                class: "man",
                extra: {
                  birthDate: "1919",
                  deathDate: "1975"
                },
                marriages: [
                  {
                    spouse: {
                      name: "Erminija Karaman",
                      class: "woman",
                      birthDate: "1919",
                      deathDate: "2010"
                    },
                    children: [
                      {
                        name: "Žarko Potočnjak",
                        class: "man",
                        extra: {
                          occupation: "Glumac",
                          birthDate: "1946"
                        },
                        marriages: [
                          {
                            spouse: {
                              name: "Asja Jovanović",
                              class: "woman"
                            },
                            children: [
                              {
                                name: "Mima Potočnjak",
                                class: "woman",
                                extra: {
                                  birthDate: "1984"
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name: "Nenad Potočnjak",
                        class: "man",
                        extra: {
                          birthDate: "1947",
                          deathDate: "1949"
                        }
                      },
                      {
                        name: "Vesna Potočnjak",
                        class: "woman",
                        extra: {
                          birthDate: "1954"
                        }
                      }
                    ]
                  }
                ]
              },
              {
                name: "Ivica Potočnjak",
                class: "man"
              },
              {
                ...elviraLjubicaPaar,
                children: [
                  {
                    name: "... Paar grana",
                    class: "subtree",
                    extra: {
                      connectionTreeId: "Paar"
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
