import dTree from "d3-dtree";
import _ from "lodash";
import * as d3 from "d3";
import PersonNode from "./PersonNode";
import React from "react";
import ReactDOM from "react-dom";

const nodeWidth = 320;
const nodeHeight = 160;
const nodeSeperationHeight = 100;

// Needed for dTree lib
window._ = _;
window.d3 = d3;

export const renderTree = (targetDomId, treeData, onChangeTree) => {
  const targetElementId = "#" + targetDomId;

  dTree.init(treeData, {
    target: targetElementId,
    debug: true,
    height: 800,
    width: 1400,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    styles: {
      node: "node",
      linage: "linage",
      marriage: "marriage",
      text: "nodeText"
    },
    callbacks: {
      nodeSize: function(nodes, width, textRenderer) {
        nodes.forEach(n => {
          n.cHeight = nodeHeight;
          if (n.data.hidden) {
            n.cWidth = 0;
          } else {
            n.cWidth = nodeWidth;
          }
        });

        return [nodeWidth, nodeHeight];
      },
      nodeHeightSeperation(_nodeWidth, nodeMaxHeight) {
        return nodeMaxHeight + nodeSeperationHeight;
      },
      nodeRenderer: function(
        name,
        _x,
        _y,
        _height,
        _width,
        extra,
        id,
        nodeClass,
        _textClass,
        _textRenderer
      ) {
        const nodeId = `diagram_node_${id}`;

        setTimeout(() => {
          ReactDOM.render(
            <PersonNode
              nodeClass={nodeClass}
              name={name}
              extra={extra}
              id={nodeId}
              onChangeTree={onChangeTree}
            />,
            document.getElementById(nodeId)
          );
        });

        return `<div id="${nodeId}"></div>`;
      }
    }
  });

  // Fix for Safari
  const svgElements = document.querySelectorAll(
    targetElementId + "> svg > g > foreignObject"
  );
  svgElements.forEach(element => {
    element.setAttribute("height", "100%");
  });
};
