import React from "react";
import "./PersonNode.css";
import {
  IoMdPerson,
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoIosText,
  IoMdBriefcase,
  IoMdPulse,
  IoIosWalk,
  IoIosHome,
  IoMdInformationCircleOutline
} from "react-icons/io";
import { GiFamilyTree } from "react-icons/gi";

function PersonNode(props) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const bornName = (props.extra && props.extra.bornName) || undefined;
  const nickname = (props.extra && props.extra.nickname) || undefined;
  const occupation = (props.extra && props.extra.occupation) || undefined;
  const birthDate = (props.extra && props.extra.birthDate) || undefined;
  const deathDate = (props.extra && props.extra.deathDate) || undefined;
  const location = (props.extra && props.extra.location) || undefined;
  const description = (props.extra && props.extra.description) || undefined;

  const connectionTreeId =
    (props.extra && props.extra.connectionTreeId) || undefined;
  const image = (props.extra && props.extra.image) || undefined;

  const hasExpanded =
    nickname || occupation || birthDate || deathDate || location || description;

  const isSubtreeNode = props.nodeClass === "subtree";

  const onNodeClick = isSubtreeNode
    ? () => props.onChangeTree(connectionTreeId)
    : () => hasExpanded && setIsExpanded(!isExpanded);

  const containerStyles =
    "person-node-container" +
    (connectionTreeId ? " person-node-container-with-connection" : "") +
    (props.nodeClass ? " " + props.nodeClass : "");

  return (
    <div className={containerStyles}>
      <div className="person-basic-info" role="button" onClick={onNodeClick}>
        <div className="person-image">
          {isSubtreeNode ? (
            <GiFamilyTree className="person-avatar" />
          ) : image ? (
            <img
              src={require("./peopleimg/" + image)}
              alt={props.name}
              width={90}
            />
          ) : (
            <IoMdPerson className="person-avatar" />
          )}
        </div>
        <div className="person-right-side">
          <div className="person-name">{props.name}</div>
          {bornName && <div className="person-info-line">Rođ. {bornName}</div>}
        </div>
        <div className="person-action">
          {connectionTreeId && !isSubtreeNode && (
            <GiFamilyTree
              className="person-tree-connection"
              title={connectionTreeId + " grana"}
              onClick={e => {
                e.stopPropagation();
                props.onChangeTree(connectionTreeId);
              }}
            />
          )}
          {hasExpanded &&
            (isExpanded ? (
              <IoIosArrowDropupCircle className="person-expand-arrow" />
            ) : (
              <IoIosArrowDropdownCircle className="person-expand-arrow" />
            ))}
        </div>
      </div>
      {isExpanded && (
        <div className="person-expanded">
          {nickname && (
            <div className="person-detail">
              <IoIosText className="person-detail-icon" />
              Nadimak: <span className="person-detail-value">{nickname}</span>
            </div>
          )}
          {occupation && (
            <div className="person-detail">
              <IoMdBriefcase className="person-detail-icon" />
              Zanimanje:
              <span className="person-detail-value">{occupation}</span>
            </div>
          )}
          {birthDate && (
            <div className="person-detail">
              <IoIosWalk className="person-detail-icon" /> Datum rođenja:
              <span className="person-detail-value">{birthDate}</span>
            </div>
          )}
          {deathDate && (
            <div className="person-detail">
              <IoMdPulse className="person-detail-icon" /> Datum smrti:{" "}
              <span className="person-detail-value">{deathDate}</span>
            </div>
          )}
          {location && (
            <div className="person-detail">
              <IoIosHome className="person-detail-icon" /> Mjesto:{" "}
              <span className="person-detail-value">{location}</span>
            </div>
          )}
          {description && (
            <div className="person-detail">
              <IoMdInformationCircleOutline className="person-detail-icon" />
              {description}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PersonNode;
